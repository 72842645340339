import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Menu from 'react-burger-menu/lib/menus/push'
import styled from 'styled-components'
import { Link } from 'gatsby'

const StyledMenu = styled.div`
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
`

export default ({pageWrapId, outerContainerId, facebook}) => {
  const lessons = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___slug] }) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              slug
              title
            }
          }
        }
      }
    }
  `)

  return (
    <StyledMenu>
      <Menu pageWrapId={pageWrapId} outerContainerId={outerContainerId} width='300px'>
        <Link to='/'>Home</Link>
        {lessons?.allMarkdownRemark?.edges.map(edge => {
          const {slug, title} = edge?.node?.frontmatter;
          return (
              <Link to={slug}>{title}</Link>
          )
        })}
        <Link to='/register'>Registration</Link>
        <a href={facebook} target="_blank" rel="noopener noreferrer" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a>
      </Menu>
    </StyledMenu>
  )
}
