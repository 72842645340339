import React from "react"

export const FormSelect = ({label, id, positioning, required, options, onChange }) => (
  <div className={positioning}>
    <label htmlFor={id}>{label}</label>
    <select required={required} name={id} id={id} onBlur={onChange}>
      {options.map((option) => (
        <option value={option.value} key={option.value}>{option.label}</option>
      ))}
    </select>
  </div>
)

export default FormSelect;
