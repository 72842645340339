import React from "react"

export const FormRadio = ({text, id, positioning, required, options }) => (
  <>
    <div className={positioning}>
      <p htmlFor={id}>{text}</p>
    </div>
    {options.map((option) => (
      <div className="col-6 col-12-small">
        <input type="radio" id={`${id}-${option.id}`} value={`${option.id}`} required={required} name={`${id}`}onChange={option.onChange}></input>
        <label htmlFor={`${id}-${option.id}`}>{option.label}</label>
      </div>
    ))}
  </>
)

export default FormRadio;
