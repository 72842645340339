import React from "react"
import {FormInput, FormTextarea } from './form';

const ContactForm = () => (
  <>
    <h2>Contact</h2>
    <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="gems-contact">
      <div className="row gtr-uniform">
        <FormInput
          positioning="col-6 col-12-xsmall"
          label="Name"
          id="name"
          required
        />
        <FormInput
          positioning="col-6 col-12-xsmall"
          label="Email"
          id="email"
          type="email"
          required
        />
        <FormTextarea
          positioning="col-12"
          label="Message"
          id="message"
          required
        />

        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="gems-contact" />

        <div className="col-12">
          <ul className="actions">
            <li><input type="submit" value="Send Message" /></li>
          </ul>
        </div>
      </div>
    </form>
  </>
)

export default ContactForm;
