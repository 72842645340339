import React from "react"

export const FormInput = ({label, id, positioning, required, type, pattern, min, max, defaultValue, onChange }) => (
  <div className={positioning}>
    <label htmlFor={id}>{label}</label>
    <input type={type ?? 'text'} required={required} name={id} id={id} min={min} max={max} pattern={pattern} defaultValue={defaultValue} onChange={onChange} />
  </div>
)

export default FormInput;
