import React, { useState } from "react"

import { FormInput, FormSelect, FormTextarea } from '.';

export const RegistrationGirl = ({number}) => {
  const [girlName, setGirlName] = useState(`Girl ${number}`)

  const updateGirlName = event => {
    setGirlName(event.target.value || `Girl ${number}`);
  };

  return (
    <>
      <div className="col-12">
        <h3>{girlName}</h3>
      </div>
      <FormInput 
        positioning="col-6 col-12-xsmall"
        label="Name"
        id={`child-${number}-name`}
        required
        onChange={updateGirlName}
      />
      <FormInput 
        positioning="col-6 col-12-xsmall"
        label="Birthdate"
        id={`child-${number}-birthdate`}
        type="date"
        required
      />
      <FormInput 
        positioning="col-9 col-12-xsmall"
        label="School"
        id={`child-${number}-school`}
        required
      />
      <FormSelect
        positioning="col-3 col-12-xsmall"
        label="Grade"
        id={`child-${number}-grade`}
        required
        options={[
          {value: "1", label: "1"},
          {value: "2", label: "2"},
          {value: "3", label: "3"},
          {value: "4", label: "4"},
          {value: "5", label: "5"},
          {value: "6", label: "6"},
          {value: "7", label: "7"},
          {value: "8", label: "8"},
        ]}
      />
      <FormTextarea
        positioning="col-12"
        label="Medical History, Medications, Allergies"
        id={`child-${number}-medical`}
        required
      />
    </>
  )
}

export default RegistrationGirl;
