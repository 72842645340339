import React from "react"
import PropTypes from "prop-types"

export default function Header({siteTitle}) {
  return (
    <header id="header">
      <div className="logo">{siteTitle}</div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
}
