import React from "react"

import ContactForm from "./contact-form"

const Footer = ({noContactForm}) => (
  <>
    {!noContactForm &&
      <footer id="footer">
        <section>
          <ContactForm />
        </section>
      </footer>
    }

    <div id="copyright">
      <ul><li>&copy; Untitled</li><li>Design: <a href="https://html5up.net">HTML5 UP</a></li></ul>
    </div>
  </>
)

export default Footer
