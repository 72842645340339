/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import styled from "styled-components"
import SideBar from "./menu"

const MainWrap = styled.div`
    height: 100vh;
    overflow: auto;
`;
const PageWrap = styled.div`
    text-align: center;
    overflow: auto;
    height: 100vh;
`;

const Layout = ({ location, children, noContactForm }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          facebook
        }
      }
    }
  `)

  return (
    <MainWrap id={"outer-container"}>
      <SideBar pageWrapId={"page-wrap"} outerContainerId={"main-wrap"} facebook={data.site.siteMetadata.facebook}/>
      <PageWrap id={"page-wrap"}>
        <div id="wrapper" className="fade-in">
          <Header
            location={location}
            siteTitle={data.site.siteMetadata.title}
            siteDescription={data.site.siteMetadata.description}
          />
          <div id="main">
            {children}
            <Footer noContactForm={noContactForm} />
          </div>
          <div className="bg fixed"></div>
        </div>
      </PageWrap>
    </MainWrap>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
