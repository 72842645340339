import React from "react"

export const FormTextarea = ({label, id, positioning, required, rows, hidden }) => (
  <div className={positioning} style={hidden ? {display: 'none'} : {}}>
    <label htmlFor={id}>{label}</label>
    <textarea required={required} name={id} id={id} rows={rows ?? "3"} style={{resize: 'none'}} />
  </div>
)

export default FormTextarea;
